<template>
  <div class="out-div">
    <div class="equ-main animated fadeInUp">
      <div class="row-out" v-if="equipmentName != 'grow'">
        <div class="row-one-data">
          <span>设备状态：</span>
          <div class="status" :class="online == 1 ? '' : 'active'">
            <span>{{ online == 1 ? "在线" : "离线" }}</span>
          </div>
        </div>
      </div>
      <div class="outer">
        <page-left
          :btnShow="false"
          :config="pageLeftConfig"
          :lists="allDataList"
          @changeItems="changeItems"
          v-show="equipmentName !== 'shuifei'"
        ></page-left>
        <div
          :class="['row-data', equipmentName === 'water' ? 'shush-data' : '']"
        >
          <!-- 测试 -->
          <div
            class="identify shuifeiyiti"
            v-show="equipmentName === 'shuifei'"
          >
            <div class="sf-box">
              <img
                class="structure-img"
                :src="
                  $imgOnlineUrl + '/equipment/chemical/shuifei_structure.png'
                "
                alt=""
              />
              <el-switch
                class="sf-switch sf-switch-1"
                v-model="sfSwitchValue1"
              ></el-switch>
              <el-switch
                class="sf-switch sf-switch-2"
                v-model="sfSwitchValue2"
              ></el-switch>
              <el-switch
                class="sf-switch sf-switch-3"
                v-model="sfSwitchValue3"
              ></el-switch>
              <el-switch
                class="sf-switch sf-switch-4"
                v-model="sfSwitchValue4"
              ></el-switch>
              <el-switch
                class="sf-switch sf-switch-5"
                v-model="sfSwitchValue5"
              ></el-switch>
              <el-switch
                class="sf-switch sf-switch-6"
                v-model="sfSwitchValue6"
              ></el-switch>
              <el-switch
                class="sf-switch sf-switch-7"
                v-model="sfSwitchValue7"
              ></el-switch>
              <el-switch
                class="sf-switch sf-switch-8"
                v-model="sfSwitchValue8"
              ></el-switch>
              <div class="info-win win-1">
                <div class="info-win-inner">
                  <span>累计流量：0L</span>
                  <span>瞬时流量：0L/h</span>
                </div>
              </div>
              <div class="info-win win-2">
                <div class="info-win-inner">
                  <span>累计流量：0L</span>
                  <span>瞬时流量：0L/h</span>
                </div>
              </div>
              <div class="info-win win-3">
                <div class="info-win-inner">
                  <span>累计流量：0L</span>
                  <span>瞬时流量：0L/h</span>
                </div>
              </div>
              <div class="info-win win-4">
                <div class="info-win-inner">
                  <span>进口EC：430uS/cm</span>
                  <span>进口PH：7.3</span>
                </div>
              </div>
              <div class="info-win win-5">
                <div class="info-win-inner">进口压力:1.6</div>
              </div>
              <div class="info-win win-6">
                <div class="info-win-inner">
                  <span>累计流量：560L</span>
                  <span>瞬时流量：113L/h</span>
                </div>
              </div>
            </div>
            <!--           
            <el-empty
              v-show="!identifyList.length"
              description="暂无数据"
            ></el-empty> -->
          </div>
          <!-- 测试结束 -->
          <div
            class="identify"
            v-show="equipmentName === 'insect' && nowTitle === 'insect-img'"
          >
            <div class="identify-per-class">
              <span>选择日期</span>
              <el-date-picker
                class="my-date-picker"
                v-model="identifyTime"
                :clearable="false"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="changeIdentifyDate"
              ></el-date-picker>
            </div>
            <div class="identify-list" v-show="identifyList.length">
              <div
                class="identify-list-item"
                v-for="t in identifyList"
                :key="t.id"
              >
                <img class="imgs" :src="t.img" alt="" />
                <div class="bottom-row">
                  <span class="upload-time">{{ t.upload_time }}</span>
                  <span class="analyse" @click="analyseShow(t)">分析</span>
                </div>
              </div>
            </div>
            <el-empty
              v-show="!identifyList.length"
              description="暂无数据"
            ></el-empty>
          </div>
          <div
            class="identify identify-chart"
            v-show="
              equipmentName === 'insect' && nowTitle === 'insect-quantity'
            "
          >
            <div class="identify-per-class">
              <span>选择日期</span>
              <el-date-picker
                class="my-date-picker"
                v-model="identifyChartTime"
                :clearable="false"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="changeChartIdentifyDate"
              ></el-date-picker>
            </div>
            <div class="identify-list" v-if="picOption.series[0].data.length">
              <v-chart class="v-chart" autoresize :option="picOption"></v-chart>
            </div>
            <el-empty v-else description="暂无数据"></el-empty>
          </div>
          <div
            class="items-lists"
            :class="{
              active_chongqing: equipmentName === 'insect',
            }"
            v-show="
              nowTitle !== 'insect-quantity' &&
              nowTitle !== 'insect-img' &&
              equipmentName !== 'grow' &&
              equipmentName !== 'shuifei'
            "
          >
            <div
              v-for="(t, i) in dataList"
              :key="t.id"
              :style="{ 'background-color': t.color }"
              :class="{
                'data-items': true,
                active_shuizhi:
                  equipmentName === 'water' ||
                  equipmentName === 'insect' ||
                  equipmentName === 'gate',
              }"
            >
              <!-- 展示类 -->
              <!-- :class="[t.online ? '' : 'active']" -->
              <div class="div-Type0" v-if="t.type === 0">
                <div :class="['status', t.online == 1 ? '' : 'active']">
                  <div class="yuan"></div>
                  <span v-if="t.online == 1" class="zaixian">在线</span>
                  <span v-else class="lixian">离线</span>
                </div>
                <img
                  class="iconImg"
                  v-if="t.alias"
                  :src="
                    $imgOnlineUrl + '/equipment/chemical/' + t.alias + '.png'
                  "
                />
                <div class="otherInfo" @click="showLog(t.id)">
                  <div class="num" v-if="typeof t.last_data == 'number'">
                    <VueCountUp
                      class="count-up"
                      :start-value="0"
                      :end-value="t.last_data"
                      :duration="1"
                      :decimals="
                        String(t.last_data).indexOf('.') + 1 > 1 ? '1' : '0'
                      "
                    />
                    <span :class="['company']">{{ t.data_unit }}</span>
                  </div>
                  <div class="num" v-else>
                    <span class="count-up">{{ t.last_data }}</span>
                    <span class="company">{{ t.data_unit }}</span>
                  </div>
                  <span :class="['msg']">{{ t.title }}</span>
                </div>
              </div>
              <!-- 交互类 -->
              <div class="div-Type1" v-if="t.type === 1">
                <div class="top-cont">
                  <div class="top-left">
                    <img
                      class="top-left-img"
                      :src="$imgOnlineUrl + '/equipment/chemical/icon1.png'"
                    />
                    <span>{{ t.title }}</span>
                  </div>
                  <div class="top-right">
                    <span class="switch-txt">{{
                      (switchTxt = t.last_data ? "开" : "关")
                    }}</span>
                    <el-switch
                      v-model="t.last_data"
                      active-color="#fff"
                      inactive-color="#fff"
                      :active-value="1"
                      :inactive-value="0"
                      @change="changeSwitch($event, t.id)"
                    >
                    </el-switch>
                  </div>
                </div>
                <div class="bottom-cont">
                  <span class="bottom-cont-num">{{ t.last_data }}</span>
                  <div class="bottom-cont-time">
                    <img
                      :src="$imgOnlineUrl + '/equipment/chemical/time.png'"
                    />
                    <span>{{ t.last_time ? t.last_time : "-" }}</span>
                  </div>
                </div>
              </div>
              <!-- 数值类 -->
              <div class="div-Type2" v-if="t.type === 2">
                <div class="top-cont">
                  <div class="top-left">
                    <img
                      class="top-left-img"
                      :src="$imgOnlineUrl + '/equipment/chemical/icon1.png'"
                    />
                    <span>{{ t.title }}</span>
                  </div>
                  <div class="top-right">
                    <span class="switch-txt" v-show="isEditShow !== i">{{
                      (editCount = t.last_data)
                    }}</span>
                    <input
                      class="edit-input"
                      v-show="isEditShow === i"
                      type="text"
                      v-model.trim="t.last_data"
                      @blur="blur($event, t.id)"
                    />

                    <img
                      v-show="isEditShow !== i"
                      class="edit-img"
                      :src="$imgOnlineUrl + '/equipment/chemical/edit.png'"
                      @click="editNum(i)"
                    />
                  </div>
                </div>
                <div class="bottom-cont">
                  <span class="bottom-cont-num">{{ t.last_data }}</span>
                  <div class="bottom-cont-time">
                    <img
                      :src="$imgOnlineUrl + '/equipment/chemical/time.png'"
                    />
                    <span>{{ t.last_time }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grow-video" v-if="equipmentName === 'grow'">
            <div class="toggle-btn" @click="goRefresh">
              <span>切换至{{ growMainShow ? "高清图片" : "视频监控" }}</span>
              <img
                :src="$imgOnlineUrl + '/equipment/chemical/shuaxin.png'"
                :class="isRotate ? 'isRotate' : ''"
              />
            </div>
            <div class="iframe-out" v-show="growMainShow">
              <iframe
                class="video-iframe"
                frameborder="0"
                name="video"
                scrolling="no"
                width="920"
                height="600"
                :src="
                  'https://hs.darsing.net/project/digital2/video/address?eid=' +
                  allDataList[0].id +
                  '&return_type=iframe&&template=pcLive'
                "
              ></iframe>
              <ul class="video-iframe-ul">
                <li
                  class="video-iframe-li"
                  v-for="(item, index) in videoMoveList"
                  :key="index"
                  @mousedown="blowUp(item.direction)"
                  @mouseup="zoomOut(item.direction)"
                >
                  {{ item.title }}
                </li>
              </ul>
            </div>
            <img
              class="video-pv-img"
              :src="videoPvImg"
              v-show="!growMainShow"
              alt=""
            />
          </div>
          <img
            v-if="
              nowTitle !== 'insect-quantity' &&
              equipmentName === 'insect' &&
              nowTitle != 'insect-img'
            "
            class="bg-imgs chongqing"
            :src="$imgOnlineUrl + '/equipment/chemical/chongqing.png'"
          />
          <img
            v-if="equipmentName === 'grow'"
            class="bg-imgs miaoqing"
            :src="$imgOnlineUrl + '/equipment/chemical/miaoqing.png'"
          />
          <img
            v-if="equipmentName === 'soil'"
            class="bg-imgs turang"
            :src="$imgOnlineUrl + '/equipment/chemical/turang.png'"
          />
          <img
            v-if="equipmentName === 'control'"
            class="bg-imgs dapang"
            :src="$imgOnlineUrl + '/equipment/chemical/dapang.png'"
          />
          <img
            v-if="equipmentName === 'heat'"
            class="bg-imgs jiare"
            :src="$imgOnlineUrl + '/equipment/chemical/jiare.png'"
          />
          <img
            v-if="equipmentName === 'machine'"
            class="bg-imgs nongji"
            :src="$imgOnlineUrl + '/equipment/chemical/nongji.png'"
          />
          <img
            v-if="equipmentName === 'fertilizer'"
            class="bg-imgs shuifei"
            :src="$imgOnlineUrl + '/equipment/chemical/shuifei.png'"
          />
          <img
            v-if="equipmentName === 'feed'"
            class="bg-imgs touliao"
            :src="$imgOnlineUrl + '/equipment/chemical/touliao.png'"
          />
          <img
            v-if="equipmentName === 'gate'"
            class="bg-imgs famen"
            :src="$imgOnlineUrl + '/equipment/chemical/famen.png'"
          />
          <div class="out-div" v-if="equipmentName === 'water'">
            <img
              class="shuizhi-bg-imgs shuizhi"
              :src="$imgOnlineUrl + '/equipment/chemical/shuizhi.png'"
            />
            <img
              class="shuizhi-bg-imgs shuizhibg"
              :src="$imgOnlineUrl + '/equipment/chemical/shuizhibg.png'"
            />
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      class="big-pop"
      title=" "
      :visible.sync="isLogShow"
      width="673px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="setAddClose"
    >
      <div class="big-main">
        <div class="ware-title">
          <div class="left-line"></div>
          历史记录
        </div>
        <div class="add-form">
          <div class="per-class">
            <span>选择日期</span>
            <el-date-picker
              class="my-date-picker"
              v-model="dateTime"
              :clearable="false"
              type="date"
              placeholder="选择日期"
              @change="changeDate"
            >
            </el-date-picker>
          </div>
          <v-chart
            class="v-chart"
            autoresize
            :option="line_chart_data"
          ></v-chart>
          <div class="per-class">
            <el-date-picker
              class="my-date-picker"
              v-model="rangeTime"
              :clearable="false"
              type="daterange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
            <el-button type="primary" class="btn" @click="exportData"
              >数据导出</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <My-analyse
      :isAnalyseShow="isAnalyseShow"
      :info="info"
      @beforeClose="beforeClose"
    ></My-analyse>
  </div>
</template>

<script>
import {
  getEquipmentListApi,
  getEquipmentInfoApi,
  getEquipmentChildListApi,
  postSetEquipmentChildListInfoApi,
  getEquipmentChildListInfoApi,
  getVideoAddressApi,
  postMoveVideoApi,
} from "@/request/api";
import VueCountUp from "vue-countupjs";
import { mapState, mapMutations } from "vuex";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { use } from "echarts/core";
import { PieChart, BarChart, LineChart } from "echarts/charts";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import moment from "moment";
use([
  BarChart,
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  LabelLayout,
  UniversalTransition,
]);
export default {
  components: {
    VueCountUp,
    VChart,
    pageLeft: () => import("./component/pageLeft.vue"),
    MyAnalyse: () => import("../../components/MyAnalyse.vue"),
  },
  provide: {
    [THEME_KEY]: "dark",
  },
  data() {
    return {
      sfSwitchValue1: false,
      sfSwitchValue2: false,
      sfSwitchValue3: false,
      sfSwitchValue4: false,
      sfSwitchValue5: false,
      sfSwitchValue6: false,
      sfSwitchValue7: false,
      sfSwitchValue8: false,
      picOption: {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "transparent",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      },
      videoMoveList: [
        {
          title: "光学放大",
          direction: 8,
        },
        {
          title: "光学缩小",
          direction: 9,
        },
      ],
      growMainShow: true,
      isAnalyseShow: false,
      info: {},
      chongqingTabShow: "insect",
      identifyList: [],
      identifyTime: [],
      identifyChartTime: [],
      rangeTime: "",
      dateTime: null,
      isLogShow: false,
      line_chart_data: {
        backgroundColor: "#0F243D",
        xAxis: {
          type: "category",
          data: [],
          boundaryGap: false,
          interval: 55,
        },
        yAxis: {
          name: "",
          nameLocation: "end",
          nameGap: 14,
          nameTextStyle: {
            color: "#fff",
            fontSize: 12,
            padding: [0, 0, 0, 30],
          },

          type: "value",
          splitNumber: 6,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#36B77F",
          borderColor: "#36B77F",
          padding: [7, 12],
          formatter: "时间：{b}<br/> 数值：{c}",
          textStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            type: "line",
            data: [],
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: "rgb(54,183,127)",
              width: 2,
            },
            itemStyle: {
              borderWidth: 2,
              borderColor: "#36B77F",
              color: "#fff",
            },
            areaStyle: {
              color: "rgba(54,183,127,.28)",
            },
          },
        ],
      },
      pageLeftConfig: {
        placeholder: "搜索设备名称",
        img: this.$imgOnlineUrl + "/equipment/meteorological/icon5.png",
      },
      // 交互类
      switchValue: true,
      switchTxt: "",
      editCount: "",
      // 数值类
      isEditShow: undefined,
      // 展示类
      colorList: [
        "#3679FC",
        "#9D60B5",
        "#0FB4BA",
        "#91CB74",
        "#EE6666",
        "#35BFFE",
        "#F0934D",
        "#6E71EE",
        "#A8BBA3",
        "#A8BBA3",
      ],
      channel_id: "", //设备分类ID
      table_id: "",
      nowTable_id: "",
      nowTitle: "",
      child_id: "",
      // 状态
      online: 1,
      data_value: "",
      data_type: "",
      allDataList: [],
      dataList: [],
      videoPvImg: "",
      isRotate: false,
      clearT: null,
    };
  },
  watch: {
    "$route.query"(newVal) {
      this.channel_id = newVal;
      this.dataList = [];
      this.getEquipmentListApi();
    },
    nowTitle(newVal) {
      this.identifyTime = [
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD"),
      ];
      this.identifyChartTime = [
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD"),
      ];
      if (newVal === "insect-img") {
        this.changeIdentifyDate();
      }
      if (newVal === "insect-quantity") {
        this.changeChartIdentifyDate();
      }
    },
  },
  beforeDestroy() {
    if (this.clearT) {
      clearTimeout(this.clearT);
      this.clearT = null;
    }
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      equipmentName: (state) => state.user.equipmentName,
    }),
  },
  created() {
    this.getEquipmentListApi();
  },
  methods: {
    ...mapMutations("user", ["SET_EQUIPMENT_Name"]),
    async blowUp(direction) {
      await postMoveVideoApi({
        direction,
        project_id: 2,
        table_id: this.allDataList[0].id,
        speed: 1,
        act: "start",
      });
    },
    async zoomOut(direction) {
      await postMoveVideoApi({
        direction,
        project_id: 2,

        table_id: this.allDataList[0].id,
        speed: 1,
        act: "stop",
      });
    },
    goRefresh() {
      this.growMainShow = !this.growMainShow;
      if (!this.growMainShow) {
        this.getVideoAddressApi();
      }
      this.isRotate = true;
      this.clearT = setTimeout(() => {
        this.isRotate = false;
      }, 500);
    },
    changeIdentifyDate() {
      let day = moment(this.identifyTime[0]).format("YYYY-MM-DD") || null;
      let end_day = moment(this.identifyTime[1]).format("YYYY-MM-DD") || null;
      this.getIdentifyList(day, end_day);
    },
    changeChartIdentifyDate() {
      let day = moment(this.identifyChartTime[0]).format("YYYY-MM-DD") || null;
      let end_day =
        moment(this.identifyChartTime[1]).format("YYYY-MM-DD") || null;
      this.getIdentifyChartList(day, end_day);
    },
    beforeClose() {
      this.isAnalyseShow = false;
      this.info = {};
    },
    analyseShow(t) {
      this.isAnalyseShow = true;
      this.info = t;
    },
    changeItems(table_id) {
      this.identifyList = [];
      this.nowTable_id = table_id.id;
      this.nowTitle = table_id.alias;
      this.getEquipmentChildListApi(table_id.id);
    },
    showLog(id) {
      this.dateTime = moment(new Date()).format("YYYY-MM-DD");
      this.isLogShow = true;
      this.child_id = id;
      this.changeDate();
    },
    async changeDate() {
      let day = moment(this.dateTime).format("YYYY-MM-DD");
      const res = await getEquipmentChildListInfoApi({
        manor_id: this.farmID,
        table_id: this.nowTable_id,
        child_id: this.child_id,
        day,
        end_day: day,
        act: "history",
      });
      if (res.code == 0) {
        this.line_chart_data["xAxis"]["data"] = [];
        this.line_chart_data["series"][0]["data"] = [];
        res.results.data.forEach((a) => {
          let date = new Date(a["x"]);
          let h =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          let xAxis = h + ":" + date.getMinutes();
          this.line_chart_data["xAxis"]["data"].push(xAxis);
          this.line_chart_data["series"][0]["data"].push(a["y"]);
        });
        this.line_chart_data["yAxis"]["name"] =
          res.view.title + res.view.data_unit;
      }
    },
    async getIdentifyList(day, end_day) {
      const res = await getEquipmentInfoApi({
        manor_id: this.farmID,
        table_id: this.nowTable_id,
        day,
        end_day,
        act: "status",
      });
      if (res.code === 0) {
        this.identifyList = res.results.data;
      }
    },
    async getIdentifyChartList(day, end_day) {
      this.picOption.series[0].data = [];

      const res = await getEquipmentInfoApi({
        manor_id: this.farmID,
        table_id: this.nowTable_id,
        day,
        end_day,
        act: "status",
      });
      if (res.code === 0) {
        let item = {};
        for (let i = 0; i < res.results.data.length; i++) {
          item[res.results.data[i].name] =
            ~~item[res.results.data[i].name] + res.results.data[i].num;
        }
        let keys = Object.keys(item);
        let values = Object.values(item);
        for (let k = 0; k < keys.length; k++) {
          let obj = {};
          obj.name = keys[k];
          obj.value = values[k];
          this.picOption.series[0].data.push(obj);
        }
      }
    },
    async exportData() {
      if (!this.rangeTime) {
        this.$notify.error({
          title: "错误",
          message: "请选择日期范围",
        });
        return;
      }
      let day = moment(this.rangeTime[0]).format("YYYY-MM-DD");
      let end_day = moment(this.rangeTime[1]).format("YYYY-MM-DD");
      const res = await getEquipmentChildListInfoApi({
        manor_id: this.farmID,
        table_id: this.allDataList[0].id,
        child_id: this.child_id,
        day,
        end_day,
        act: "history",
      });
      if (res.code === 0) {
        //列标题，逗号隔开，每一个逗号就是隔开一个单元格
        let str = `时间,数值\n`;
        //增加\t为了不让表格显示科学计数法或者其他格式
        for (let a of res.results.data) {
          let date = new Date(a.x);
          let x =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate() +
            " " +
            date.getHours() +
            ":" +
            date.getMinutes();
          str += x + "\t," + a.y + "\n";
        }
        //encodeURIComponent解决中文乱码
        let uri =
          "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
        //通过创建a标签实现
        let link = document.createElement("a");
        link.href = uri;
        //对下载的文件命名
        link.download =
          this.line_chart_data["yAxis"]["name"] +
          "[" +
          day +
          " 至 " +
          end_day +
          "]数据.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    setAddClose() {
      this.isLogShow = false;
      this.dateTime = "";
      this.rangeTime = "";
      this.line_chart_data["xAxis"]["data"] = [];
      this.line_chart_data["series"][0]["data"] = [];
      this.line_chart_data["yAxis"]["name"] = "";
    },
    // 失去光标
    blur(e, id) {
      this.child_id = id;
      this.data_type = 2;
      this.data_value = e.target._value;
      this.postSetEquipmentChildListInfoApi();
      this.isEditShow = undefined;
    },
    handleClick(tab) {
      this.chongqingTabShow = tab.name;
    },
    // 修改数值
    editNum(i) {
      this.isEditShow = i;
    },
    // 开关的切换
    changeSwitch(e, id) {
      this.child_id = id;
      this.data_type = 1;
      this.data_value = e;
      this.switchTxt = e ? "开" : "关";
      this.postSetEquipmentChildListInfoApi();
    },
    // 设备节点设置
    async postSetEquipmentChildListInfoApi() {
      const { code, msg } = await postSetEquipmentChildListInfoApi({
        manor_id: this.farmID,
        table_id: this.nowTable_id,
        child_id: this.child_id, //子信息ID
        data_type: this.data_type, //数据类型：1交互，2数值
        data_value: this.data_value, //交互类是0/1，数据类是实际值
      });
      if (code === 0) {
        this.$notify({
          title: "成功",
          message: "已修改！",
          type: "success",
        });
      } else {
        this.$notify({
          title: "提示",
          message: msg,
        });
      }
    },
    // 设备列表
    async getEquipmentListApi() {
      this.channel_id =
        this.$route.query.id || localStorage.getItem("equipmentId");
      const { code, results } = await getEquipmentListApi({
        manor_id: this.farmID,
        channel_id: this.channel_id,
      });

      if (code === 0 && Array.isArray(results)) {
        this.allDataList = results;
        this.nowTitle = this.allDataList[0].alias || "";
        if (this.equipmentName === "grow") {
          this.getVideoAddressApi();
        }
        this.nowTable_id = results[0].id;
        this.getEquipmentChildListApi(this.allDataList[0].id);
      } else {
        this.allDataList = [];
        this.dataList = [];
      }
    },
    async getVideoAddressApi() {
      const { code, data } = await getVideoAddressApi({
        eid: this.allDataList[0].id,
        return_type: "photo",
        quality: 1,
      });
      if (code === 200) {
        this.videoPvImg = data.url;
      }
    },
    // 设备详情 获取数据值
    async getEquipmentInfoApi_status(table_id) {
      const { code, results } = await getEquipmentInfoApi({
        manor_id: this.farmID,
        table_id,
        act: "status",
      });
      if (code == 0) {
        this.online = results.online;
        for (let key in this.dataList) {
          let data1 = this.dataList[key].number;
          for (let keys in results.data) {
            if (results.data[keys].number == data1) {
              this.dataList[key].last_data = results.data[keys].last_data;
              this.dataList[key].online = results.data[keys].online;
            }
          }
        }
        this.$forceUpdate();
      }
    },
    // 设备节点列表
    async getEquipmentChildListApi(table_id) {
      const { code, results } = await getEquipmentChildListApi({
        manor_id: this.farmID,
        table_id,
      });
      if (code === 0 && results) {
        this.colorList.forEach((item, index) => {
          if (results[index]) {
            results[index]["color"] = item;
          }
        });
        this.dataList = results;
        this.getEquipmentInfoApi_status(table_id);
      }
    },
    editTime(index) {
      this.isEditShow = index;
    },
    openTime() {
      this.isEditShow = null;
    },
    closeTime() {
      this.isEditShow = null;
    },
  },
};
</script>

<style lang="less" scoped>
@keyframes imgRotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}
.out-div {
  padding: 0 48px;
  .equ-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 22px 0px 20px;
    .row-out {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .row-one-data {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .status {
          display: block;
          width: 55px;
          height: 15px;
          color: chartreuse;
          display: flex;
          align-items: center;

          &::before {
            content: "";
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: chartreuse;
            margin-right: 6px;
          }
          &.active {
            color: brown;
          }
          &.active::before {
            background-color: brown;
          }
        }
      }
      .log {
        width: 70px;
        height: 12px;
        color: #fff;
        cursor: pointer;
      }
    }
    .outer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #071a2c;

      .row-data {
        // width: 1300px;
        flex: 1;
        height: 800px;
        background: #0e2138;
        position: relative;
        &.shuizhi-data {
          width: 70%;
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          .data-items {
            float: inherit !important;
          }
        }
        /deep/.my-tabs {
          padding: 0 20px;
          .el-tabs__item {
            color: #fff;
          }
        }
        .identify {
          width: 100%;
          display: flex;
          height: 800px;
          flex-direction: column;
          padding: 20px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 5px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #3e90e5;
          }
          /deep/.identify-per-class {
            color: #fff;
            margin-bottom: 20px;
            span {
              margin-right: 20px;
            }
            .el-input__inner {
              background-color: #081c30;
              border-color: #385576;
              .el-range-input {
                background-color: #081c30;
              }
            }
            .el-range-separator {
              color: #fff;
            }
          }
          .identify-list {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .identify-list-item {
              width: 240px;
              height: 220px;
              margin: 0 20px 20px 0;
              display: flex;
              flex-direction: column;
              background-color: #0e2f53;
              .imgs {
                width: 100%;
                height: 180px;
              }
              .bottom-row {
                width: 100%;
                height: 40px;
                font-size: 14px;
                padding: 12px 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #fff;
                .analyse {
                  cursor: pointer;
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
          &.shuifeiyiti {
            /deep/.sf-box {
              width: 100%;
              height: 100%;
              position: relative;
              .structure-img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 1800px;
                height: 100%;
              }
              .sf-switch {
                position: absolute;
                &.sf-switch-1 {
                  top: 180px;
                  left: 230px;
                }
                &.sf-switch-2 {
                  top: 214px;
                  left: 684px;
                }
                &.sf-switch-3 {
                  top: 398px;
                  left: 684px;
                }
                &.sf-switch-4 {
                  top: 578px;
                  left: 684px;
                }
                &.sf-switch-5 {
                  top: 240px;
                  left: 828px;
                }
                &.sf-switch-6 {
                  top: 423px;
                  left: 828px;
                }
                &.sf-switch-7 {
                  top: 604px;
                  left: 828px;
                }
                &.sf-switch-8 {
                  top: 612px;
                  left: 1336px;
                }
              }

              .info-win {
                min-width: 180px;
                height: 60px;
                background-color: rgb(34, 68, 114);
                border-radius: 10px;
                position: absolute;

                .info-win-inner {
                  position: relative;
                  min-width: 180px;
                  color: #fff;
                  font-size: 14px;
                  width: 100%;
                  height: 100%;
                  padding: 4px 14px;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-around;

                  &::after {
                    content: "";
                    position: absolute;
                    bottom: -18px;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 0;
                    width: 0;
                    border-top: 10px solid rgb(34, 68, 114);
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                  }
                }
                &.win-1 {
                  top: 170px;
                  left: 983px;
                }
                &.win-2 {
                  top: 355px;
                  left: 983px;
                }
                &.win-3 {
                  top: 535px;
                  left: 983px;
                }
                &.win-4 {
                  top: 525px;
                  left: 1220px;
                }
                &.win-5 {
                  top: 60px;
                  left: 500px;
                  .info-win-inner {
                    &::after {
                      top: 50%;
                      right: -16px;
                      bottom: initial;
                      left: initial;
                      transform: translateY(-50%);
                      border-top: 10px solid transparent;
                      border-left: 10px solid rgb(34, 68, 114);
                      border-right: 10px solid transparent;
                      border-bottom: 10px solid transparent;
                    }
                  }
                }
                &.win-6 {
                  top: 60px;
                  left: 860px;
                  .info-win-inner {
                    &::after {
                      top: 50%;
                      right: -16px;
                      bottom: initial;
                      left: initial;
                      transform: translateY(-50%);
                      border-top: 10px solid transparent;
                      border-left: 10px solid rgb(34, 68, 114);
                      border-right: 10px solid transparent;
                      border-bottom: 10px solid transparent;
                    }
                  }
                }
              }
            }
          }
        }
        .identify-chart {
          height: 100%;
          .identify-list {
            width: 100%;
            height: 100%;
            .v-chart {
              width: 100%;
              height: 100%;
            }
          }
        }
        .items-lists {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          padding: 20px;
          position: relative;
          z-index: 100;
          .data-items {
            height: 120px;
            min-width: 246px;
            border-radius: 5px;
            margin: 0 16px 20px 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .div-Type0,
            .div-Type1,
            .div-Type2 {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              .status {
                width: 50px;
                height: 20px;
                border-radius: 40px;
                background-color: rgba(0, 0, 0, 0.3);
                position: absolute;
                top: 8px;
                right: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                .zaixian {
                  color: chartreuse;
                }
                .lixian {
                  color: red;
                }
                .yuan {
                  width: 8px;
                  height: 8px;
                  background-color: chartreuse;
                  border-radius: 50%;
                  margin: 0 4px 0 0;
                }
                &.active {
                  color: red;
                  .yuan {
                    background-color: red;
                  }
                }
              }
              .iconImg {
                width: 74px;
                height: 74px;
                margin-right: 14px;
              }
              .otherInfo {
                flex: 1;
                .num {
                  margin-bottom: 12px;
                  .count-up,
                  .company {
                    font-size: 30px;
                    font-weight: bold;
                    color: #fff;
                  }
                }
                .msg {
                  font-size: 16px;
                  color: #fff;
                }
              }
            }
            .div-Type0 {
              padding: 0 20px;
              cursor: pointer;
            }
            .div-Type1,
            .div-Type2 {
              flex-direction: column;
              padding: 0 30px;
              justify-content: space-around;
              .top-cont {
                width: 100%;
                display: flex;
                justify-content: space-between;
                .top-left {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  font-size: 14px;
                  color: #fff;
                  .top-left-img {
                    width: 42px;
                    height: 42px;
                    margin-bottom: 9px;
                  }
                }
                .top-right {
                  display: flex;
                  align-items: center;
                  .switch-txt {
                    font-size: 14px;
                    color: #fff;
                    margin-right: 16px;
                  }
                  // 开关
                  /deep/.el-switch {
                    width: 52px;
                    height: 24px;
                    .el-switch__core:after {
                      background-color: #ccc;
                    }
                  }
                  // 开关-开启状态
                  /deep/.el-switch.is-checked {
                    .el-switch__core::after {
                      background-color: #2878ff;
                    }
                  }
                  .edit-img {
                    width: 20px;
                    height: 18px;
                    cursor: pointer;
                  }
                  .edit-input {
                    width: 30%;
                    // border: none;
                    border-radius: 6px;
                    background-color: rgba(255, 255, 255, 0.3);
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    height: 20px;
                    &:focus {
                      border: none;
                    }
                  }
                }
              }
              .bottom-cont {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .bottom-cont-num {
                  font-size: 18px;
                  color: #fff;
                  font-weight: bold;
                }
                .bottom-cont-time {
                  display: flex;
                  align-items: center;
                  img {
                    width: 12px;
                    height: 12px;
                    margin-right: 6px;
                  }
                  span {
                    font-size: 14px;
                    color: #fff;
                  }
                }
              }
            }
            .div-Type2 {
              .top-right {
                justify-content: flex-end;
              }
            }
          }
        }
        .grow-video {
          width: 920px;
          height: 660px;
          overflow: hidden;
          position: relative;
          z-index: 100;
          display: flex;
          flex-direction: column;
          .video-pv-img {
            width: 100%;
            height: 600px;
          }
          .toggle-btn {
            width: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: 16px;
            padding: 10px;
            color: #2d6fb4;
            img {
              width: 18px;
              margin-left: 10px;
            }
            .isRotate {
              animation: imgRotate 0.5s linear 0s 1 alternate;
              -webkit-animation: imgRotate 0.5s linear 0s 1 alternate;
            }
          }
          .iframe-out {
            width: 900px;
            height: 600px;
            display: flex;
            flex-direction: column;
            .video-iframe-ul {
              width: 100%;
              display: flex;
              padding: 10px 0;
              .video-iframe-li {
                font-size: 14px;
                margin-right: 10px;
                cursor: pointer;
                &:hover {
                  color: #3e90e5;
                }
              }
            }
          }
        }
        .bg-imgs {
          height: auto;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: 98;
          &.turang {
            width: 1338px;
            bottom: -18px;
          }
          &.dapang {
            width: 920px;
            bottom: -39px;
          }
          &.jiare {
            width: 1110px;
            bottom: -29px;
          }
          &.nongji {
            width: 1300px;
            bottom: -33px;
          }
          &.touliao {
            width: 1000px;

            bottom: -27px;
          }
          &.famen {
            width: 340px;
            left: initial;
            right: 0px;
            bottom: -20px;
          }
          &.shuifei {
            width: 1280px;
            bottom: -18px;
          }
          &.chongqing {
            width: 1320px;
            bottom: -22px;
            right: 0;
          }
          &.miaoqing {
            width: 1320px;
            bottom: -22px;
            left: initial;
            transform: translateX(0);
            right: -15px;
          }
        }
        .out-div {
          width: 1580px;
          position: absolute;
          right: 0;
          bottom: 0;
          z-index: 98;

          .shuizhi-bg-imgs {
            position: absolute;
            bottom: 0;

            &.shuizhi {
              width: 431px;
              right: 20px;
              bottom: -31px;
            }
            &.shuizhibg {
              width: 900px;
              right: 0;
            }
          }
        }
      }
    }

    .other {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .electric,
      .tap,
      .time {
        width: 352px;
        min-height: 599px;
        background: #081c30;
        margin-right: 15px;
        display: flex;
        flex-direction: column;
        padding: 27px 23px;
        justify-content: space-between;

        .title {
          display: flex;
          align-items: center;
          margin-bottom: 33px;

          img {
            width: 28px;
            height: 28px;
            margin-right: 11px;
          }

          span {
            font-size: 22px;
            color: #fff;
          }
        }

        .main {
          width: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;

          .main-items {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 0 25px;
            height: 38px;
            margin-bottom: 18px;
            background: #0e2f53;
            justify-content: space-between;

            .main-msg {
              font-size: 14px;
              color: #fff;
            }
          }
        }

        .update-time {
          width: 100%;
          text-align: center;
          font-size: 14px;
          color: #99b7d9;
        }
      }
      .tap {
        width: 996px;

        .tap-main {
          width: 100%;
          flex: 1;
          display: flex;
          flex-wrap: wrap;

          .main {
            margin-right: 26px;
            width: 31.33%;
            height: auto;
            flex: none;
            &:nth-of-type(3n) {
              margin-right: 0px;
            }
          }
        }
      }

      .time {
        margin-right: 0;

        .time-main {
          .main-items {
            .num-box {
              span {
                font-size: 14px;
                color: #fff;
              }

              input {
                width: 81px;
                height: 26px;
                background: #081c30;
                border: 1px solid #385576;
                font-size: 14px;
                color: #fff;
                padding: 0 0 0 7px;
              }
            }

            .icon-box {
              display: flex;
              justify-content: flex-end;

              img {
                margin-right: 2px;
                width: 18px;
                cursor: pointer;
                height: 18px;
              }

              img.edit-imgs {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }

  .big-pop {
    /deep/.el-dialog {
      width: 673px !important;
      background-color: inherit;
      margin-top: 0 !important;

      .el-dialog__header {
        padding: 16px 20px 10px;
      }
    }

    /deep/.el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__headerbtn {
      font-size: 26px;
      top: 71px;
      right: 52px;
      font-weight: bold;
    }

    /deep/.el-button span {
      color: #fff;
    }

    /deep/.dialog-footer {
      padding: 0 0 0 119px;

      .el-form-item__content {
        width: 353px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 44px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }

    .big-main {
      width: 673px;
      height: 861px;
      background: url("../../assets/image/task/popbgc.png") no-repeat center
        center;
      background-size: cover;
      padding: 45px 55px 0 55px;

      .ware-title {
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-bottom: 40px;

        .left-line {
          background-color: #fff;
          width: 4px;
          height: 21px;
          border-radius: 3px;
          margin-right: 10px;
        }
      }
      .add-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: scroll;
        height: calc(100% - 150px);

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }
        .per-class {
          color: #fff;
          margin-bottom: 20px;
          span {
            margin-right: 20px;
          }
          .btn {
            margin-left: 20px;
          }
        }
        .my-date-picker {
        }
        .v-chart {
          width: 100%;
          height: 500px;
        }
      }

      /deep/.el-input__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
      }

      /deep/.el-form-item__label {
        font-size: 14px;
        color: #fff;
        padding: 0 24px 0 0;
      }

      /deep/.el-date-editor--datetimerange.el-input,
      /deep/.el-date-editor--datetimerange.el-input__inner {
        width: 353px;
      }

      /deep/.el-input__inner {
        background-color: #081c30;
        border-color: #385576;
      }

      /deep/.el-date-editor .el-range-input {
        background-color: #081c30;
        color: #57708d;
        width: 44%;
      }

      /deep/.el-date-editor .el-range-separator {
        color: #fff;
      }

      /deep/.type .el-input__inner {
        width: 136px;
      }

      /deep/.type .el-select {
        width: 136px;
        margin-right: 26px;
      }

      /deep/.type .el-form-item__content {
        display: flex;
      }

      /deep/.type .el-select:nth-of-type(2) {
        margin-right: 0px;
      }

      /deep/.type-col.el-form-item.type {
        display: flex;
        .el-form-item__content {
          margin-left: 0 !important;
        }
      }

      /deep/.detail-form,
      /deep/.complete-form {
        .el-form-item__content {
          p {
            color: #fff;
          }
        }
      }

      /deep/.detail-form {
        .el-form-item {
          margin-bottom: 15px;
        }

        .el-form-item__label,
        .el-form-item__content {
          line-height: 30px;
        }
      }
    }
  }
}
</style>
